import * as React from "react"
import { Link } from "gatsby"

import Bio from "../components/bio"
import RecentPosts from "../components/recent_posts"
import CategoryList from "../components/category-list"
import TagList from "../components/tag-list"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath
  let header

  if (isRootPath) {
    header = (
      <p className="main-heading">
        <Link to="/">sakama.dev</Link>
      </p>
    )
  } else {
    header = (
      <p className="main-heading">
        <Link to="/">sakama.dev</Link>
      </p>
    )
  }

  return (
    <div className="global-wrapper" data-is-root-path={isRootPath}>
      <div className="global-menu clearfix">
        <header>{header}</header>
        <ul>
          <li><a href="/">Main</a></li>
          <li><a href="/about/">About</a></li>
        </ul>
      </div>
      <div class="container">
        <div className="main-wrapper">
          <main>{children}</main>
          <footer>
            © {new Date().getFullYear()}, sakama.dev - Built with
            {` `}
            <a href="https://www.gatsbyjs.com">Gatsby</a>
          </footer>
        </div>

        <div className="right-wrapper">
          <section className="author">
            <h4>Author</h4>
            <Bio />
            <h5>Socials</h5>
            <p className="github"><a href="https://github.com/sakama">github.com/sakama</a></p>
            <p className="twitter"><a href="https://twitter.com/oreradio">twitter: oreradio</a></p>
          </section>

          <section className="article">
            <h4>Recent Posts</h4>
            <RecentPosts />
          </section>
          <section className="article">
            <h4>Categories</h4>
            <CategoryList />
          </section>
          <section className="article tag-list">
            <h4>Tags</h4>
            <TagList />
          </section>
        </div>
      </div>
    </div>
  )
}

export default Layout
