import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"

const RecentPosts = () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(limit: 5, sort: { order: DESC, fields: [frontmatter___date] }) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                date(formatString: "YYYY/MM/DD")
              }
            }
          }
        }
      }
    `}
    render={data => (
      <nav>
        <ul>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Link to={node.fields.slug}>
                <li>{node.frontmatter.title}</li>
            </Link>
          ))}
        </ul>
      </nav>
    )}
  />
)

export default RecentPosts